export const getPropertyOptionsMapping = db => {
  const { propertyOptionsMapping } = db.shopData
  return {
    getRsTypeCategories: () => {
      return Object.entries(propertyOptionsMapping["rs_type"] || {}).map(([value, label]) => ({
        value,
        label,
        children: Object.entries(propertyOptionsMapping[`${value.toLowerCase()}_type`] || {}).map(([value, label]) => ({
          value,
          label,
        })),
      }))
    },
    getRsTypes: () => {
      return propertyOptionsMapping["rs_type"]
    },
    getMarketingTypes: () => {
      return propertyOptionsMapping["marketing_type"]
    },
    getObjectTypes: () => {
      return propertyOptionsMapping["object_type"]
    },
    getRecommendedUseTypes: () => {
      return propertyOptionsMapping["recommended_use_types"]
    },
    getUtilizationTradeSite: () => {
      return propertyOptionsMapping["utilization_trade_site"]
    },
    getConditions: () => {
      return propertyOptionsMapping["condition"]
    },
    getInvestmentCategories: () => {
      return propertyOptionsMapping["investment_category"]
    },
    getPurchaseForms: () => {
      return propertyOptionsMapping["purchase_form"]
    },
  }
}

export const getRelevantRsTypes = (db, for_saved_queries = false) => {
  const { getRsTypes } = getPropertyOptionsMapping(db)
  const types = getRsTypes()
  return Object.entries(types || []).reduce(
    (prev, [value, label]) => [
      ...prev,
      ...(!for_saved_queries ||
      !db.shopData.allowedRsTypesInSavedQueries ||
      !db.shopData.allowedRsTypesInSavedQueries.length ||
      db.shopData.allowedRsTypesInSavedQueries.includes(value)
        ? [{ value, label }]
        : []),
    ],
    []
  )
}

const uniqueBy = (iter, fn) => Object.values(iter.reduce((prev, cur) => ({ ...prev, [fn(cur)]: cur }), {}))

export const getRelevantRsCategories = (db, for_saved_queries = false) => {
  const { getRsTypeCategories } = getPropertyOptionsMapping(db)
  const customCategories = (db.shopData.customCategories || []).map(({ id, name }) => ({ value: id, label: name }))
  const categories = uniqueBy(
    (getRsTypeCategories() || []).reduce(
      (prev, cur) => [
        ...prev,
        ...(!for_saved_queries ||
        !db.shopData.allowedRsTypesInSavedQueries ||
        !db.shopData.allowedRsTypesInSavedQueries.length ||
        db.shopData.allowedRsTypesInSavedQueries.includes(cur.value)
          ? cur.children
          : []),
      ],
      []
    ),
    cur => cur.value
  )

  return categories.concat(customCategories)
}

export const getObjectTypes = db => {
  const { getObjectTypes } = getPropertyOptionsMapping(db)
  const types = getObjectTypes()
  return Object.entries(types || []).map(([value, label]) => ({ value, label }))
}

export const getMarketingTypes = db => {
  const { getMarketingTypes } = getPropertyOptionsMapping(db)
  const types = getMarketingTypes()
  return Object.entries(types || []).map(([value, label]) => ({ value, label }))
}

export const getConditions = db => {
  const { getConditions } = getPropertyOptionsMapping(db)
  const conditions = getConditions()
  return Object.entries(conditions || []).map(([value, label]) => ({ value, label }))
}

export const getRecommendedUseTypes = db => {
  const { getRecommendedUseTypes } = getPropertyOptionsMapping(db)
  const recommendedUseTypes = getRecommendedUseTypes()
  return Object.entries(recommendedUseTypes || []).map(([value, label]) => ({ value, label }))
}
export const getUtilizationTradeSite = db => {
  const { getUtilizationTradeSite } = getPropertyOptionsMapping(db)
  const utilizationTradeSite = getUtilizationTradeSite()
  return Object.entries(utilizationTradeSite || []).map(([value, label]) => ({ value, label }))
}

export const getInvestmentCategories = db => {
  const { getInvestmentCategories } = getPropertyOptionsMapping(db)
  const investmentCategories = getInvestmentCategories()
  return Object.entries(investmentCategories || []).map(([value, label]) => ({ value, label }))
}

export const getPurchaseForms = db => {
  const { getPurchaseForms } = getPropertyOptionsMapping(db)
  const purchaseForms = getPurchaseForms()
  return Object.entries(purchaseForms || []).map(([value, label]) => ({ value, label }))
}
